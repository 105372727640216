//
// Toto je pokus vylepsit spravanie povodneho layoutu tak, aby fungoval dobre s kontajnermi a Bulmou.
// Pouzil som CSS Grid.
//

.grid-layout {
  clear: both;

  &.grid-with-sidebar {
    display: grid;

    // 210px je sirka sidebaru s okrajmi
    grid-template-columns: 210px 1fr;
    grid-column-gap: 10px;

    .sidebar {
      grid-column-start: 1;
    }

    .main-body {
      grid-column-start: 2;
    }

  }

}
