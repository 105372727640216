/*
 * QLIS base style sheet for Vue components.
 */


// Bulma + Buefy
@import 'buefy';


// Attempt for a better UI
@import 'new-ui';
