@charset "utf-8";

//
// Fixes of CSS / variables, so that Bulma works within Qlis legacy HTML.
//
//
//   https://bulma.io/documentation/customize/
//


// Variable fixes / overrides
$modal-z: 200;


// Style fixes
@mixin qlis-legacy-fixes {
  // Opravy pre Qlis
  //font-size: initial; // prilis velke
  font-size: 14px;
  box-sizing: border-box;

  .button {
    margin: 0;
  }
}
