@charset "utf-8";

// Bulma/Buefy restricted for containers with class `bulma`
.bulma {

  // Customized Bulma for Qlis
  @import "../../bulma/bulma-fixes";
  @import "../../bulma/bulma-qlis";
  @include qlis-legacy-fixes;

  // Import Bulma and Buefy styles
  @import "~bulma";
  @import "~buefy/src/scss/buefy";

  &.legacy {
    // tu som chcel zmensit velkost fontu nastaveneho v Bulme, ale asi to musim urobit globalne
  }
}
